import * as React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faCalendar } from "@fortawesome/free-regular-svg-icons"
import { faHashtag } from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby";

const _ = require('lodash')
const PostMeta = ({ date, categories, tags }) => {
  // console.log(date,categories,tags)
  return (
    <div className="post-meta">
      <div className="post-meta_item">
        <span className="icon"><FontAwesomeIcon icon={faCalendar} /></span>
        <time className="time" dateTime="{date}">{date}</time>
      </div>
      {categories.length > 0 && (
        <div className="post-meta_item meta-item-list">
          <ul className="categories">
            {categories.map((cat, i) => {
              const cat_uri = '/category/' + fixedEncodeURI(cat) + '/'
              return (
                <li key={i}><span className="icon"><FontAwesomeIcon icon={faFolder} /></span><Link to={cat_uri} itemProp="url">{cat}</Link></li>
              )
            })}
          </ul>
        </div>
      )}
      {tags.length > 0 && (
        <div className="post-meta_item meta-item-list">
          <ul className="tags">
            {tags.map((tag, i) => {
              const tag_uri = '/tag/' + fixedEncodeURI(tag) + '/'
              return (<li key={i}><span className="icon"><FontAwesomeIcon icon={faHashtag}/></span><Link to={tag_uri} itemProp="url">{tag}</Link></li>)
            })}
          </ul>
        </div>
      )}

    </div>
  )
}

function fixedEncodeURI(str) {
  return encodeURI(_.kebabCase(str).replace(/[.]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  }));
}

PostMeta.defaultProps = {
  date: '',
  categories: [],
  tags: [],
}

PostMeta.propTypes = {
  date: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default PostMeta
